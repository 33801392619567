<template>
  <div>
    <b-row>
      <table class="table table-sm ">
        <thead>
          <tr>
            <th scope="col" class="table-primary">Price Calculation</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="some-radios" class="border-bottom">Contract Type </label>
          <b-form-radio-group disabled stacked class="border-bottom pb-1">
            <b-form-radio v-model="vehicleData.priceContractType" name="contract-type" class="mt-2" value="1">
              Buy-in
            </b-form-radio>
            <b-form-radio v-model="vehicleData.priceContractType" name="contract-type" class="mt-2" value="2">
              Trade-in
            </b-form-radio>
            <!-- <b-form-radio v-model="vehicleData.priceContractType" name="contract-type" class="mt-2" value="3">
              Lease Return (in-house)
            </b-form-radio> -->
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="8" xl="9">
        <table class="table border table-sm">
          <tr>
            <td scope="col" class="text-right font-weight-bold">Price Of Vehicle</td>
            <td scope="col" v-if="vehicleData.appraisedVehiclePrice != null">{{ formatPrice(vehicleData.appraisedVehiclePrice) }}</td>
            <td scope="col" v-else>{{ formatPrice(0) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Trade-in Credit Issued</td>
            <td scope="col">
              <cleave id="options" disabled style="height:2.142rem" v-model="vehicleData.creditIssued" class="form-control" :raw="true" :options="options.number" />
            </td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Price Difference</td>
            <td scope="col">{{ formatPrice(priceDifference) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">G.S.T Rate on Vehicle</td>
            <cleave id="options" disabled style="height:2.142rem" v-model="vehicleData.gstRateVehicle" class="form-control" :raw="true" :options="options.percent" />
          </tr>

          <tr>
            <td scope="col" class="text-right">G.S.T Amount on Vehicle</td>
            <td scope="col">{{ formatPrice(gstAmountOnVehicle) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right font-weight-bold">Total Vehicle Purchase Price</td>
            <td scope="col">{{ formatPrice(priceDifference + gstAmountOnVehicle) }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <b-row>
      <table class="table border  text-center table-condensed table-sm mt-1">
        <thead>
          <tr>
            <th scope="col" class="text-capitalize">Additional Purchase Options</th>
            <th scope="col" style="width:10%" class="text-capitalize">Option Price</th>
            <th scope="col" style="width:10%" class="text-capitalize">G.S.T Rate</th>
            <th scope="col" class="text-capitalize">G.S.T Amount</th>
            <th scope="col" style="width:10%" class="text-capitalize">P.S.T Rate</th>
            <th scope="col" class="text-capitalize">P.S.T Amount</th>
            <th scope="col" class="text-capitalize">Option Sub-Total</th>
            <th scope="col" class="text-capitalize">#</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <b-form-input id="options" disabled placeholder="Additional Purchase Options" size="sm" v-model="priceCalculationTemp.pcOptions" type="text" />
            </td>
            <td>
              <cleave id="pcPrice" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPrice" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td>
              <cleave id="pcPrice" disabled placeholder="G.S.T Rate" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcGst" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td>
              <cleave id="pcGstAmount" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcGstAmount" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td>
              <cleave id="pcpst" disabled placeholder="P.S.T  Rate" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPst" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td>
              <cleave id="pcPstAmount" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPstAmount" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td>
              <cleave id="pcSubTotal" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcSubTotal" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="priceCalculationTemp.pcOptions == null || priceCalculationTemp.pcOptions == '' || priceCalculationTemp.pcPrice == null || priceCalculationTemp.pcPrice == ''" @click="priceCalculation()" class="btn-icon" variant="primary">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>

          <tr style="text-align:left" v-for="(item, index) in vehicleData.priceCalculationValues" :key="index">
            <td>{{ item.pcOptions }}</td>
            <td>{{ formatPrice(item.pcPrice) }}</td>
            <td>{{ formatPrice(item.pcGst) }} %</td>
            <td>{{ formatPrice(item.pcGstAmount) }}</td>

            <td>{{ formatPrice(item.pcPst) }} %</td>
            <td>{{ formatPrice(item.pcPstAmount) }}</td>
            <td>{{ formatPrice(item.pcSubTotal) }}</td>

            <td class="text-center"><feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeAdditionalItem(index)" size="16" class="cursor-pointer mx-1" /></td>
          </tr>
          <tr>
            <td class="bg-secondary" colspan="8"></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Total Additional Purchase Option Amount</td>
            <td>{{ formatPrice(optionAmountTotal) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right font-weight-bold">Total Purchase Contract Amount</td>
            <td>{{ formatPrice(contractAmount) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Less Net Payout Before Tax</td>
            <td v-if="vehicleData.beforeTax != null">{{ formatPrice(vehicleData.beforeTax * -1) }}</td>
            <td v-else>{{ formatPrice(0) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right font-weight-bold">Purchase Contract Balance Due</td>
            <td>{{ formatPrice(contractAmount - (vehicleData.beforeTax ? vehicleData.beforeTax : 0)) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right text-danger">Payable By Seller</td>
            <td>
              <cleave id="options" placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="vehicleData.payableBySeller" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BButton } from 'bootstrap-vue';
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,

    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,

      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'priceCalculationTemp.pcPrice': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPrice != null || this.priceCalculationTemp.pcGst != '' || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcGstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst;

          this.priceCalculationTemp.pcPstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst;

          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcGst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcGst != null || this.priceCalculationTemp.pcGst != '') {
          this.priceCalculationTemp.pcGstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcGst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcPst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPst != null || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcPstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcPst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },
  },

  computed: {
    priceDifference() {
      if (this.vehicleData.appraisedVehiclePrice == null || this.vehicleData.appraisedVehiclePrice == '') {
        return 0;
      } else if (this.vehicleData.creditIssued == null || this.vehicleData.creditIssued == '') {
        return Number(this.vehicleData.appraisedVehiclePrice);
      } else {
        return Number(this.vehicleData.appraisedVehiclePrice - this.vehicleData.creditIssued);
      }
    },

    gstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        return 0;
      } else if (this.vehicleData.gstRateVehicle == null || this.vehicleData.gstRateVehicle == '') {
        return 0;
      } else {
        return Number((this.priceDifference / 100) * this.vehicleData.gstRateVehicle);
      }
    },

    optionAmountTotal() {
      var total = 0;
      this.vehicleData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcSubTotal);
      });

      return total;
    },

    contractAmount() {
      return Number(this.optionAmountTotal) + Number(this.priceDifference) + Number(this.gstAmountOnVehicle);
    },
  },

  methods: {
    priceCalculation() {
      this.vehicleData.priceCalculationValues.push(this.priceCalculationTemp);
      this.priceCalculationTemp = {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      };
    },

    removeAdditionalItem(e) {
      this.$delete(this.vehicleData.priceCalculationValues, e);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>

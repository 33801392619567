<template>
  <inventory-create-page></inventory-create-page>
</template>

<script>
import InventoryCreatePage from './InventoryCreatePage.vue';

export default {
  components: {
    InventoryCreatePage,
  },

  data() {
    return {};
  },
};
</script>

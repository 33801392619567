<template>
  <validation-observer ref="vehicleRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Vehicle Detail Specifications</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <!-- :before-change="validationForm1" -->

    <b-row class="mt-1">
      <h6>General Information</h6>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12" md="4">
        <b-form-group label="Model Variation" label-for="Model Variation">
          <b-form-input id="modelVariation" v-model="vehicleData.modelVariation" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Body Type" label-for="body">
          <v-select
            v-model="vehicleData.body"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'body' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.body"
            :options="dropAll['body']"
            :reduce="(val) => val.id"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Interior Trim" label-for="Interior Trim">
          <b-form-input id="interiorTrim" v-model="vehicleData.interiorTrim" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Seating Capacity" label-for="seatingCapacity">
          <vue-numeric-input v-model="vehicleData.seatingCapacity" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem; " :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Number of Doors" label-for="numberOfDoors">
          <vue-numeric-input v-model="vehicleData.numberOfDoors" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem; " :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Chassis Code" label-for="Chassis Code">
          <b-form-input id="chassisCode" v-model="vehicleData.chassisCode" type="text" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <h6>Engine Technical Data</h6>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12" md="3">
        <b-form-group label="Engine Code" label-for="Engine Code">
          <b-form-input id="engineCode" v-model="vehicleData.engineCode" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Number of Cylinders" label-for="Number of Cylinders">
          <b-form-input id="numberOfCylinders" v-model="vehicleData.numberOfCylinders" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Engine Alignment" label-for="Engine Alignment">
          <b-form-input id="engineAlignment" v-model="vehicleData.engineAlignment" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Engine Position" label-for="Engine Position">
          <b-form-input id="enginePosition" v-model="vehicleData.enginePosition" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Engine Displacement" label-for="Engine Displacement">
          <b-form-input id="engineDisplacement" v-model="vehicleData.engineDisplacement" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Fuel Type" label-for="fuel">
          <v-select
            v-model="vehicleData.fuel"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'fuel' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.fuel"
            :reduce="(val) => val.id"
            :options="dropAll['fuel']"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Fuel Tank Capacity" label-for="Fuel Tank Capacity">
          <b-form-input id="fuelTankCapacity" v-model="vehicleData.fuelTankCapacity" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Fuel System" label-for="Fuel System">
          <b-form-input id="fuelSystem" v-model="vehicleData.fuelSystem" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Bore x Stroke" label-for="Bore x Stroke">
          <b-form-input id="boreStroke" v-model="vehicleData.boreStroke" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Number of Valves" label-for="Number of Valves">
          <b-form-input id="numberOfValves" v-model="vehicleData.numberOfValves" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Aspiration" label-for="Aspiration">
          <b-form-input id="aspiration" v-model="vehicleData.aspiration" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Compression Ratio" label-for="Compression Ratio">
          <b-form-input id="compressionRatio" v-model="vehicleData.compressionRatio" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Maximum Horsepower" label-for="Maximum Horsepower">
          <b-form-input id="maximumHorsepower" v-model="vehicleData.maximumHorsepower" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Maximum Torque" label-for="Maximum Torque">
          <b-form-input id="maximumTorque" v-model="vehicleData.maximumTorque" type="text" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <h6>Drivetrain, brakes and suspension</h6>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12" md="3">
        <b-form-group label="Drivetrain" label-for="drivetrain">
          <v-select
            v-model="vehicleData.drivetrain"
            :taggable="true"
            append-to-body
            :create-option="(temp) => ({ id: 0, value: temp, who: 'drivetrain' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.drivetrain"
            :options="dropAll['drivetrain']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Front Suspension" label-for="Front Suspension">
          <b-form-input id="frontSuspension" v-model="vehicleData.frontSuspension" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Rear Suspension" label-for="Rear Suspension">
          <b-form-input id="rearSuspension" v-model="vehicleData.rearSuspension" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Chassis Type" label-for="Chassis Type">
          <b-form-input id="chassisType" v-model="vehicleData.chassisType" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Chassis Materials" label-for="Chassis Materials">
          <b-form-input id="chassisMaterials" v-model="vehicleData.chassisMaterials" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Front Brakes - Disc Dimensions" label-for="Front Brakes">
          <b-form-input id="frontBrakes" v-model="vehicleData.frontBrakes" type="text" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-group label="Rear Brakes - Disc Dimensions" label-for="Rear Brakes">
          <b-form-input id="rearBrakes" v-model="vehicleData.rearBrakes" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Front Tyres - Rims Dimensions" label-for="Front Tyres">
          <b-form-input id="frontTyres" v-model="vehicleData.frontTyres" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Rear Tyres - Rims Dimensions" label-for="Rear Tyres">
          <b-form-input id="rearTyres" v-model="vehicleData.rearTyres" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Transmission" label-for="transmmission">
          <v-select
            v-model="vehicleData.transmmission"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'transmmission' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.transmmission"
            :options="dropAll['transmmission']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <h6>Size and Dimensions</h6>
    </b-row>

    <b-row class="mt-1">
      <b-col cols="12" md="3">
        <b-form-group label="Length" label-for="Length">
          <b-form-input id="length" v-model="vehicleData.length" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Width" label-for="Width">
          <b-form-input id="width" v-model="vehicleData.width" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Height" label-for="Height">
          <b-form-input id="height" v-model="vehicleData.height" type="text" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-group label="Wheelbase" label-for="Wheelbase">
          <b-form-input id="wheelbase" v-model="vehicleData.wheelbase" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Front Axle Width" label-for="Front Axle Width">
          <b-form-input id="frontAxleWidth" v-model="vehicleData.frontAxleWidth" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Rear Axle Width" label-for="Rear Axle Width">
          <b-form-input id="rearAxleWidth" v-model="vehicleData.rearAxleWidth" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group label="Curb Weight" label-for="Curb Weight">
          <b-form-input id="curbWeight" v-model="vehicleData.curbWeight" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    VueNumericInput,
    ToastificationContent,
    //validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
    dropAll: {
      required: true,
    },
    formShow: {
      required: false,
    },
  },
  data() {
    var wherefrom = router.currentRoute.name;
    return {
      wherefrom,
      dropdownLoading: false,
    };
  },
  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;
      switch (val.who) {
        case 'body':
          if (this.dropAll['body'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveBodyType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['body'].unshift(response.data);
                this.vehicleData.body = response.data.id;
                this.toastMessage('Body Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'fuel':
          if (this.dropAll['fuel'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveFuelType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['fuel'].unshift(response.data);
                this.vehicleData.fuel = response.data.id;
                this.toastMessage('Fuel Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'drivetrain':
          if (this.dropAll['drivetrain'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveDrivetrain', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['drivetrain'].unshift(response.data);
                this.vehicleData.drivetrain = response.data.id;
                this.toastMessage('Drivetrain');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'transmmission':
          if (this.dropAll['transmmission'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveTransmmissionType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['transmmission'].unshift(response.data);
                this.vehicleData.transmmission = response.data.id;
                this.toastMessage('Transmission');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        default:
          break;
      }
    },
    selectedDropdownValue(val) {
      switch (val.who) {
        case 'body':
          this.vehicleData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.body != undefined && this.vehicleData.body != 0) {
            this.vehicleData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'fuel':
          this.vehicleData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.fuel != undefined && this.vehicleData.fuel != 0) {
            this.vehicleData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'drivetrain':
          this.vehicleData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.drivetrain != undefined && this.vehicleData.drivetrain != 0) {
            this.vehicleData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'transmmission':
          this.vehicleData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.transmmission != undefined && this.vehicleData.transmmission != 0) {
            this.vehicleData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        default:
          break;
      }
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>

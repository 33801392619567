<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <!-- Default Add -->
    <form-wizard
      v-if="wherefrom == 'inventory-add' || wherefrom == 'inventory-edit'"
      color="#3178cf"
      ref="wizard"
      scope="props"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      finish-button-text="Save"
      back-button-text="Previous"
      class="vertical-steps steps-transparent"
      @on-complete="formSubmitted"
    >
      <tab-content title="Description of Vehicle" icon="feather icon-file-text">
        <description-of-vehicle :vehicleData.sync="vehicleData" :getAllDropDown.sync="getAllDropDown" :formShow.sync="formShow" :dropAll.sync="dropAll" ref="subDescriptionComponent"></description-of-vehicle>
      </tab-content>

      <tab-content title="Detail Specification" v-if="$Can('vehicle_details_edit')" icon="feather icon-file-text">
        <vehicle-detail-specification :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll"></vehicle-detail-specification>
      </tab-content>
      <!-- 
      <tab-content title="Detail Specification - 2" v-if="userRole == 'supermanager'" icon="feather icon-file-text">
        <vehicle-detail-specification2 :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll"></vehicle-detail-specification2>
      </tab-content> -->

      <tab-content title="MSRP Configuration" v-if="$Can('vehicle_details_edit')" icon="feather icon-file-text">
        <msrp-configuration :vehicleData.sync="vehicleData" :msrpOptions.sync="msrpOptions" :dropAll.sync="dropAll"></msrp-configuration>
      </tab-content>

      <tab-content title="Accessories Registration" v-if="$Can('vehicle_details_edit')" icon="feather icon-file-text">
        <accessories-registration :vehicleData.sync="vehicleData" :accessioreOption.sync="accessioreOption" :dropAll.sync="dropAll"></accessories-registration>
      </tab-content>

      <tab-content title="Vehicle Declearation" v-if="$Can('vehicle_decleration_edit')" icon="feather icon-file-text">
        <vehicle-declearation :vehicleData.sync="vehicleData" ref="subDeclearationComponent"></vehicle-declearation>
      </tab-content>
    </form-wizard>

    <!-- Vehicle Details  -->
    <form-wizard v-if="wherefrom == 'inventory-details'" color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" finish-button-text="Save" back-button-text="Previous" class="vertical-steps steps-transparent" @on-complete="formSubmitted">
      <tab-content title="Detail Specification" icon="feather icon-file-text">
        <vehicle-detail-specification :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll"></vehicle-detail-specification>
      </tab-content>

      <!--  <tab-content title="Detail Specification - 2" icon="feather icon-file-text">
        <vehicle-detail-specification2 :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll"></vehicle-detail-specification2>
      </tab-content> -->

      <tab-content title="MSRP Configuration" icon="feather icon-file-text">
        <msrp-configuration :vehicleData.sync="vehicleData" :msrpOptions.sync="msrpOptions" :dropAll.sync="dropAll"></msrp-configuration>
      </tab-content>

      <tab-content title="Accessories Registration" icon="feather icon-file-text">
        <accessories-registration :vehicleData.sync="vehicleData" :accessioreOption.sync="accessioreOption" :dropAll.sync="dropAll"></accessories-registration>
      </tab-content>
    </form-wizard>

    <!-- Vehicle Declaration  -->
    <form-wizard
      v-if="wherefrom == 'vehicle-decleration' || wherefrom == 'vehicle-purchase-decleration' || wherefrom == 'vehicle-sale-decleration' || wherefrom == 'vehicle-inception-decleration'"
      color="#3178cf"
      ref="wizard"
      scope="props"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      finish-button-text="Save"
      back-button-text="Previous"
      class="vertical-steps steps-transparent"
      @on-complete="formSubmitted"
    >
      <tab-content title="Vehicle Declearation" icon="feather icon-file-text">
        <vehicle-declearation :vehicleData.sync="vehicleData" ref="subDeclearationComponent"></vehicle-declearation>
      </tab-content>
    </form-wizard>

    <!--Vehicle Listing -->
    <form-wizard v-if="wherefrom == 'vehicle-listing'" color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" finish-button-text="Save" back-button-text="Previous" class="vertical-steps steps-transparent" @on-complete="formSubmitted">
      <tab-content title="Listing Photos" icon="feather icon-file-text">
        <vehicle-gallery :vehicleData.sync="vehicleData" :formShow.sync="formShow"></vehicle-gallery>
      </tab-content>

      <tab-content title="Listing Details" icon="feather icon-file-text">
        <vehicle-details :vehicleData.sync="vehicleData" ref="subDetailsComponent" :dropAll.sync="dropAll"></vehicle-details>
      </tab-content>
    </form-wizard>

    <!-- Purchase Or Sale  Add Page -->
    <form-wizard
      v-if="wherefrom == 'inventory-add-purchase' || wherefrom == 'inventory-add-sale' || wherefrom == 'inventory-add-inception'"
      color="#3178cf"
      ref="wizard"
      scope="props"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      finish-button-text="Save"
      back-button-text="Previous"
      class="vertical-steps steps-transparent"
      @on-complete="formSubmitted"
    >
      <tab-content title="Description of Vehicle" icon="feather icon-file-text">
        <description-of-vehicle :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll" ref="subDescriptionComponent"></description-of-vehicle>
      </tab-content>
    </form-wizard>

    <!-- Purchase Or Sale  Edit Page -->
    <form-wizard
      v-if="wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-trade' || wherefrom == 'inventory-edit-inception'"
      color="#3178cf"
      ref="wizard"
      scope="props"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      finish-button-text="Save"
      back-button-text="Previous"
      class="vertical-steps steps-transparent"
      @on-complete="formSubmitted"
    >
      <tab-content title="Description of Vehicle" icon="feather icon-file-text">
        <description-of-vehicle :vehicleData.sync="vehicleData" :formShow.sync="formShow" :dropAll.sync="dropAll" ref="subDescriptionComponent"></description-of-vehicle>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import { BContainer, BOverlay } from 'bootstrap-vue';
import inventoryStoreModule from '../inventoryStoreModule';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';
import { required, email } from '@validations';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DescriptionOfVehicle from './DescriptionOfVehicle.vue';
import VehicleDetailSpecification from './VehicleDetailSpecification.vue';
//import VehicleDetailSpecification2 from './VehicleDetailSpecification2.vue';
import MsrpConfiguration from './MsrpConfiguration.vue';
import AccessoriesRegistration from './AccessoriesRegistration.vue';
import VehicleDeclearation from './VehicleDeclearation.vue';
import VehicleAppraisal from './VehicleAppraisal.vue';
import VehicleGallery from './VehicleGallery.vue';
import VehicleDetails from './VehicleDetails.vue';
import PriceCalculation from './PriceCalculation.vue';
import _ from 'lodash';

export default {
  components: {
    BContainer,
    BOverlay,
    FormWizard,
    TabContent,
    WizardButton,
    required,
    email,
    //TabPage
    DescriptionOfVehicle,
    VehicleDetailSpecification,
    //VehicleDetailSpecification2,
    MsrpConfiguration,
    AccessoriesRegistration,
    VehicleDeclearation,
    VehicleAppraisal,
    VehicleGallery,
    PriceCalculation,
    VehicleDetails,
  },
  directives: {
    Ripple,
  },
  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'inventory';
    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME);
    });
  },
  watch: {},
  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
  },
  created() {
    this.getAllDropDown();
    this.getByIdInventory();
  },
  data() {
    var wherefrom = router.currentRoute.name;
    var vehicleDataModel = {
      title: '',
      listingPrice: null,
      listingPriceHistory: [{ price: 0, rateOf: 0, beforePrice: 0, time: null }],
      description: null,
      homepagestatus: 0,
      collectionListing: 0,
      selectedImage: null,
      condition: null,
      mileageUnit: null,
      extColour: null,
      modelYear: null,
      mileageValue: null,
      intColour: null,
      make: null,
      body: null,
      fuel: null,
      model: null,
      seatingCapacity: null,
      drivetrain: null,
      trim: null,
      numberOfDoors: null,
      transmmission: null,
      stockNumber: null,
      vinNo: null,
      origenalMarket: null,
      msrpCurrency: null,
      baseMsrp: null,
      msrpOptions: [],
      accessioreOptions: [],
      vehicleDeclearation: [null, null, null, null, null, null, null],
      vehicleDeclearationText: null,
      vehicleTitleType: null,
      appraisedVehiclePrice: null,
      appraisalNotes: null,
      payoutType: null,
      payoutTax: null,
      payoutGstRate: null,
      payoutGstAmount: null,
      payoutTotalAmount: null,
      inventoryGallery: [],
      deleteImages: [],
      priceContractType: null,
      creditIssued: null,
      gstRateVehicle: null,
      payableBySeller: null,
      inventoryNumber: null,
      completionDate: null,
      inventoryDate: null,
      location: null,
      comments: null,
      beforeTax: null,
      unitlDate: null,
      gstRate: null,
      gstAmount: null,
      totalAmount: null,
      leaseListing: 0,
      leased: 0,
    };
    return {
      wherefrom,
      userRole: JSON.parse(localStorage.getItem('userData')).role,
      id: router.currentRoute.params.id,
      formShow: false,
      baseURL: store.state.app.baseURL,
      vehicleData: vehicleDataModel,
      dropAll: [],
      lienCustomerData: {},
      sellerCustomerData: {},
      dropCustomers: [],
      msrpValueOption: null,
      msrpValueOptions: [],
      msrpPriceOption: null,
      msrpPriceOptions: [],
      accessioreValueOptions: [],
      accessioreQuantityOptions: null,
      msrpOptions: [],
      msrpOption: [],
      accessioreOption: [],
      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },
      nextTodoId: 1,
      dropdownLoading: false,
    };
  },
  methods: {
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    formSubmitted() {
      switch (this.wherefrom) {
        case 'inventory-add':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;
        case 'inventory-add-purchase':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;
        case 'inventory-add-sale':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;

        case 'inventory-add-inception':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;

        case 'inventory-edit':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;
        case 'inventory-edit-sale':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;

        case 'inventory-edit-inception':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;

        case 'inventory-edit-purchase':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;
        case 'inventory-edit-trade':
          this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            } else {
              if (this.userRole == 'supermanager') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
              }
            }
          });
          break;
        case 'inventory-details':
          this.saveOrUpdateInventory();
          break;
        case 'vehicle-listing':
          this.saveOrUpdateInventory();
          break;
        case 'vehicle-decleration':
          this.$refs.subDeclearationComponent.$refs.declerationRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            }
          });
          break;
        case 'vehicle-purchase-decleration':
          this.$refs.subDeclearationComponent.$refs.declerationRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            }
          });
          break;
        case 'vehicle-sale-decleration':
          this.$refs.subDeclearationComponent.$refs.declerationRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            }
          });
          break;

        case 'vehicle-inception-decleration':
          this.$refs.subDeclearationComponent.$refs.declerationRules.validate().then((success) => {
            if (success) {
              this.saveOrUpdateInventory();
            }
          });
          break;

        default:
          //  this.saveOrUpdateInventory();
          break;
      }
    },
    saveOrUpdateInventory() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        if (this.vehicleData.archive == 'no') {
          //listing price history
          const d = new Date();
          if (this.vehicleData.listingPriceHistory[0].rateOf == 0 && this.vehicleData.listingPriceHistory[0].beforePrice == 0 && this.vehicleData.listingPriceHistory[0].time == null) {
            let history = {
              price: parseFloat(this.vehicleData.listingPrice),
              rateOf: 0,
              beforePrice: 0,
              time: d.toLocaleDateString() + '-' + d.toLocaleTimeString(),
            };
            this.vehicleData.listingPriceHistory = [history];
          } else if (this.vehicleData.listingPriceHistory[this.vehicleData.listingPriceHistory.length - 1].price == this.vehicleData.listingPrice) {
            //check price
          } else if (this.vehicleData.listingPrice > 0 && this.vehicleData.listingPriceHistory.length > 0) {
            let history = {
              price: this.vehicleData.listingPrice,
              rateOf: (((this.vehicleData.listingPrice - this.vehicleData.listingPriceHistory[0].price) / this.vehicleData.listingPriceHistory[0].price) * 100).toFixed(2),
              beforePrice: this.vehicleData.listingPriceHistory[this.vehicleData.listingPriceHistory.length - 1].price,
              time: d.toLocaleDateString() + '-' + d.toLocaleTimeString(),
            };
            this.vehicleData.listingPriceHistory.push(history);
          }
          //

          store
            .dispatch('inventory/updateInventory', this.vehicleData)
            .then((response) => {
              if (response.data != 'alreadyUpdated') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Inventory Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                setTimeout(() => {
                  this.formShow = false;

                  if (router.currentRoute.name == 'inventory-edit-purchase') {
                    router.push({ name: 'quote-preview', params: { id: router.currentRoute.params.quoteid } });
                  } else if (router.currentRoute.name == 'vehicle-purchase-decleration') {
                    router.push({ name: 'quote-preview', params: { id: router.currentRoute.params.quoteid } });
                  } else if (router.currentRoute.name == 'vehicle-sale-decleration') {
                    router.push({ name: 'sales-preview', params: { id: router.currentRoute.params.quoteid } });
                  } else if (router.currentRoute.name == 'vehicle-inception-decleration') {
                    router.push({ name: 'testlease-preview', params: { id: router.currentRoute.params.quoteid } });
                  } else if (router.currentRoute.name == 'inventory-edit-inception') {
                    router.push({ name: 'testlease-preview', params: { id: router.currentRoute.params.quoteid } });
                  } else {
                    router.push({ name: 'inventory-view', params: { id: this.vehicleData.id } });
                  }
                }, 300);
              } else {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Please refresh the page.',
                    text: '⚠️ Data has been changed during operations. ',
                    icon: 'RefreshCwIcon',
                    variant: 'warning',
                  },
                });
              }
            })
            .catch((error) => {
              this.formShow = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
              router.push({ name: 'inventory-list' });
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Archive information cannot be updated',
              icon: 'ThumbsDownIcon',
              variant: 'warning',
            },
          });
          this.formShow = false;
        }
      } else {
        store
          .dispatch('inventory/saveInventory', this.vehicleData)
          .then((response) => {
            if (response.status == 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Inventory Insert Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              setTimeout(function() {
                this.formShow = false;
                if (router.currentRoute.name == 'inventory-add-sale') {
                  router.push({ name: 'sales-add' });
                } else if (router.currentRoute.name == 'inventory-add-purchase') {
                  router.push({ name: 'quote-add' });
                } else if (router.currentRoute.name == 'inventory-add-inception') {
                  router.push({ name: 'testlease-add' });
                } else {
                  router.push({ name: 'inventory-view', params: { id: response.data.id } });
                }
              }, 1000);
            }
          })
          .catch((e) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    getByIdInventory() {
      this.formShow = true;
      if (router.currentRoute.params.id) {
        store
          .dispatch('inventory/getInventoryById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.vehicleData = {
              id: response.data.inventory.id,
              title: response.data.inventory.title ?? '',
              listingPrice: response.data.inventory.listingPrice,
              listingPriceHistory: response.data.inventory.listingPriceHistory ?? [{ price: 0, rateOf: 0, beforePrice: 0, time: null }],
              description: response.data.inventory.description,
              archive: response.data.inventory.archive,
              homepagestatus: response.data.inventory.homepage,
              collectionListing: response.data.inventory.collection,
              condition: response.data.inventory.condition,
              mileageUnit: response.data.inventory.mileageUnit,
              extColour: response.data.inventory.extColour,
              modelYear: response.data.inventory.modelYear,
              mileageValue: response.data.inventory.mileageValue,
              intColour: response.data.inventory.intColour,
              make: response.data.inventory.make,
              body: response.data.inventory.body,
              fuel: response.data.inventory.fuel,
              model: response.data.inventory.model,
              seatingCapacity: response.data.inventory.seatingCapacity,
              drivetrain: response.data.inventory.drivetrain,
              trim: response.data.inventory.trim,
              numberOfDoors: response.data.inventory.numberOfDoors,
              transmmission: response.data.inventory.transmmission,
              stockNumber: response.data.inventory.stockNumber,
              vinNo: response.data.inventory.vinNo,
              origenalMarket: response.data.inventory.origenalMarket,
              msrpCurrency: response.data.inventory.msrpCurrency,
              baseMsrp: response.data.inventory.baseMsrp,
              msrpOptions: [],
              accessioreOptions: [],
              vehicleDeclearation: [null, null, null, null, null, null, null],
              vehicleDeclearationText: null,
              vehicleTitleType: response.data.inventory.vehicleTitleType,
              appraisedVehiclePrice: response.data.inventory.appraisedVehiclePrice,
              appraisalNotes: response.data.inventory.appraisalNotes,
              payoutType: response.data.inventory.payoutType,
              payoutTax: response.data.inventory.beforeTax,
              payoutGstRate: response.data.inventory.gstRate,
              payoutGstAmount: null,
              payoutTotalAmount: null,
              inventoryGallery: [],
              deleteImages: [],
              beforeTax: response.data.inventory.beforeTax,
              unitlDate: response.data.inventory.unitlDate,
              gstRate: response.data.inventory.gstRate,
              gstAmount: null,
              totalAmount: null,
              selectedImage: response.data.inventory.selectedImage,
              modelVariation: response.data.inventory.modelVariation,
              interiorTrim: response.data.inventory.interiorTrim,
              chassisCode: response.data.inventory.chassisCode,
              chassisType: response.data.inventory.chassisType,
              chassisMaterials: response.data.inventory.chassisMaterials,
              wheelbase: response.data.inventory.wheelbase,
              length: response.data.inventory.length,
              width: response.data.inventory.width,
              height: response.data.inventory.height,
              frontAxleWidth: response.data.inventory.frontAxleWidth,
              rearAxleWidth: response.data.inventory.rearAxleWidth,
              frontBrakes: response.data.inventory.frontBrakes,
              rearBrakes: response.data.inventory.rearBrakes,
              frontTyres: response.data.inventory.frontTyres,
              rearTyres: response.data.inventory.rearTyres,
              curbWeight: response.data.inventory.curbWeight,
              frontSuspension: response.data.inventory.frontSuspension,
              rearSuspension: response.data.inventory.rearSuspension,
              engineCode: response.data.inventory.engineCode,
              numberOfCylinders: response.data.inventory.numberOfCylinders,
              fuelTankCapacity: response.data.inventory.fuelTankCapacity,
              fuelSystem: response.data.inventory.fuelSystem,
              engineAlignment: response.data.inventory.engineAlignment,
              enginePosition: response.data.inventory.enginePosition,
              engineDisplacement: response.data.inventory.engineDisplacement,
              boreStroke: response.data.inventory.boreStroke,
              aspiration: response.data.inventory.aspiration,
              compressionRatio: response.data.inventory.compressionRatio,
              maximumHorsepower: response.data.inventory.maximumHorsepower,
              maximumTorque: response.data.inventory.maximumTorque,
              numberOfValves: response.data.inventory.numberOfValves,
              purchaseQuoteId: response.data.inventory.purchaseQuoteId,
              editable: response.data.inventory.editable,
              updated_at: response.data.inventory.updated_at,
              leaseListing: response.data.leaseListing,
              leased: response.data.leased,
            };
            this.msrpOptions = response.data.inventory.get_msrp_options;
            this.vehicleData.msrpOptions = this.msrpOptions;
            this.accessioreOption = response.data.inventory.get_accessiore_options;
            this.vehicleData.accessioreOptions = this.accessioreOption;
            response.data.inventory.get_images.forEach((element) => {
              if (element.status == 'A') {
                this.vehicleData.inventoryGallery.push(element);
              }
            });

            this.vehicleData.vehicleDeclearation = [response.data.inventory.vd1, response.data.inventory.vd2, response.data.inventory.vd3, response.data.inventory.vd4, response.data.inventory.vd5, response.data.inventory.vd6, response.data.inventory.vd7];
            this.vehicleData.vehicleDeclearationText = response.data.inventory.vd1Text;
            /*  this.vehicleData.lienPayout = {
              beforeTax: response.data.beforeTax,
              unitlDate: response.data.unitlDate,
              gstRate: response.data.gstRate,
              gstAmount: null,
              totalAmount: null,
            };*/

            //reverse listing price history
            // this.vehicleData.listingPriceHistory =  _.reverse( this.vehicleData.listingPriceHistory )
            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'inventory-list' });
          });
      } else {
        this.formShow = false;
      }
    },
    getAllDropDown() {
      store
        .dispatch('inventory/getAllDropDowns')
        .then((response) => {
          this.dropAll = response.data;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching dropdowns list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-dropdown {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
    cursor: pointer;
  }
}
</style>

<template>
  <div>
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Vehicle Appraisal & Lien</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" md="7">
        <b-form-group>
          <label for="vehicle-type" class="border-bottom">Vehicle Title Type </label>
          <b-form-radio-group disabled class="border-bottom pb-1">
            <b-form-radio v-model="vehicleData.vehicleTitleType" name="vehicle-type" value="1">
              Clean Owned
            </b-form-radio>
            <b-form-radio v-model="vehicleData.vehicleTitleType" name="vehicle-type" value="2">
              Owned with Lien Payout
            </b-form-radio>
            <b-form-radio v-model="vehicleData.vehicleTitleType" name="vehicle-type" value="3">
              Leased
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group>
          <label for="appraisedVehiclePrice">Appraised Vehicle Price</label>

          <cleave id="appraisedVehiclePrice" disabled v-model="vehicleData.appraisedVehiclePrice" class="form-control" :raw="true" :options="options.number" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12">
        <label for="appraisal-notes">Appraisal Notes</label>
        <b-form-textarea id="appraisal-notes" disabled v-model="vehicleData.appraisalNotes" rows="3" />
      </b-col>
    </b-row>

    <b-row v-show="vehicleData.vehicleTitleType == 2 || vehicleData.vehicleTitleType == 3">
      <table class="table mt-1">
        <thead>
          <tr>
            <th scope="col">Lien Payout</th>
          </tr>
        </thead>
      </table>

      <b-col cols="12" md="7">
        <b-form-group>
          <label for="payout-type" class="border-bottom">Payout Type </label>
          <b-form-radio-group disabled class="pb-1 border-bottom">
            <b-form-radio name="payout-type" v-model="vehicleData.payoutType" :disabled="vehicleData.vehicleTitleType == 3" class="mr-2" value="1">
              Finance Payout
            </b-form-radio>
            <b-form-radio name="payout-type" v-model="vehicleData.payoutType" :disabled="vehicleData.vehicleTitleType == 2" class="mr-2" value="2">
              Lease Payout
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group>
          <label for="beforeTax">Net Payout Before Tax</label>
          <cleave id="beforeTax" disabled placeholder="Option Price" v-model="vehicleData.beforeTax" class="form-control" :raw="true" :options="options.number" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group>
          <label for="unitlDate">Good Until Date</label>
          <flat-pickr id="quotationDate" disabled class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="vehicleData.unitlDate" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group>
          <label for="gstRate">G.S.T Rate</label>
          <cleave id="gstRate" disabled placeholder="Option Price" v-model="vehicleData.gstRate" class="form-control" :raw="true" :options="options.percent" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group>
          <label for="gstAmount">G.S.T Amount</label>
          <cleave id="gstAmount" disabled placeholder="Option Price" v-model="vehicleData.gstAmount" class="form-control" :raw="true" :options="options.number" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group>
          <label for="totalAmount">Total Payout Amount</label>
          <cleave id="totalAmount" disabled placeholder="Option Price" v-model="vehicleData.totalAmount" class="form-control" :raw="true" :options="options.number" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,

    VueNumericInput,
    ToastificationContent,
    Cleave,
    flatPickr,
  },

  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'vehicleData.vehicleTitleType': {
      handler: function(id, before) {
        this.vehicleData.payoutType = null;
        if (this.vehicleData.vehicleTitleType == 2) {
          this.vehicleData.payoutType = 1;
        } else if (this.vehicleData.vehicleTitleType == 3) {
          this.vehicleData.payoutType = 2;
        } else {
          this.vehicleData.payoutType = null;
          this.vehicleData.beforeTax = null;
          this.vehicleData.unitlDate = null;
          this.vehicleData.gstRate = null;
        }
      },
    },

    'vehicleData.beforeTax': {
      handler: function(id, before) {
        if (this.vehicleData.gstRate != null || this.vehicleData.gstRate != '') {
          this.vehicleData.gstAmount = (this.vehicleData.beforeTax / 100) * this.vehicleData.gstRate;
          this.vehicleData.totalAmount = Number((this.vehicleData.beforeTax / 100) * this.vehicleData.gstRate) + Number(this.vehicleData.beforeTax);
        }
      },
    },

    'vehicleData.gstRate': {
      handler: function(id, before) {
        if (this.vehicleData.beforeTax != null || this.vehicleData.beforeTax != '') {
          this.vehicleData.gstAmount = (this.vehicleData.beforeTax / 100) * this.vehicleData.gstRate;
          this.vehicleData.totalAmount = Number((this.vehicleData.beforeTax / 100) * this.vehicleData.gstRate) + Number(this.vehicleData.beforeTax);
        }
      },
    },
  },

  computed: {},

  methods: {},
};
</script>

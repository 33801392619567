import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInventorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('allInventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchArchiveInventorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('allArchiveInventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSingleInventory(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listSingleInventory/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSingleArchiveInventory(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listSingleArchiveInventory/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventorysFiltered(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('inventoryFiltered', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveInventoryFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveInventoryFile', data)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    inventorySendToArchive(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventorySendToArchive', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventorysPublish(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('inventoryPublish', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventoryView/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    inventoryClosingSheet(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventoryClosingSheet', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventoryView(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventoryView/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveInventory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    vinNoCheck(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinNoCheck', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateVehicleStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inventoryPurchaseStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateLeasePrice(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateLeasePrice/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateInventory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inventory/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateMileage(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateMileage/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateinventorydetail(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inventorydetail/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    imageUpload(ctx, data) {
      return new Promise((resolve, reject) => {
        const config = {
          onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        axiosIns
          .post('inventorygallery', data, config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropCustomer

    getCustomerDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customerDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customerDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropInventory

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventoryDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //AllDropDown

    getAllDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('alldropdown')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpPrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`singleQuote/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPreviewQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewQuote/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownload', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpPrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpprice', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveAccessioreOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('accessioreoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessioreOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessiorePrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getModelByMake(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('model/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //getModelByModel

    getTrimByModel(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('trim/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Add inventory
    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModelYear(ctx, modelYear) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('modelYear', { value: modelYear })

          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMakes(ctx, make) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('make', { value: make })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModel(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('model', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    nextIdQuotes() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('nextId')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveDrivetrain(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('drivetrain', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTrim(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('trim', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTransmmissionType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('transmmissiontype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBodyType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('body', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveFuelType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('fueltype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveExtColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('extcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveIntColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('intcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveVinNo(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinno', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveOrigenalMarket(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('origenalmarketspecs', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBaseMsrpCurrency(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('basemsrpcurrency', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchInventoryColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('inventory_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getHomeInventory(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('website/homelist')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCollectionInventory(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('website/collection')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateHomeInventoryList(ctx, newList) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('website/updateHomeList', newList)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateCollectionInventoryList(ctx, newList) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('website/updateCollectionList', newList)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchInputs(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getInventoryRequiredInputs')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vehicleRules"},[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Description of Vehicle")])])])])]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Vehicle Identification Number*","label-for":"Vehicle Identification Number"}},[_c('validation-provider',{attrs:{"name":"Vehicle Identification Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"vinNo","disabled":_vm.vehicleData.purchaseQuoteId > 0 || _vm.vehicleData.editable == 'no',"type":"text"},on:{"change":function($event){return _vm.vinNoCheck(_vm.vehicleData.vinNo)}},model:{value:(_vm.vehicleData.vinNo),callback:function ($$v) {_vm.$set(_vm.vehicleData, "vinNo", $$v)},expression:"vehicleData.vinNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Stock Number","label-for":"Stock Number"}},[_c('b-form-input',{attrs:{"id":"stockNumber","disabled":"","type":"text"},model:{value:(_vm.vehicleData.stockNumber),callback:function ($$v) {_vm.$set(_vm.vehicleData, "stockNumber", $$v)},expression:"vehicleData.stockNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Condition*","label-for":"condition"}},[_c('validation-provider',{attrs:{"name":"Condition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dropCondition,"disabled":_vm.vehicleData.editable == 'no',"state":errors.length > 0 ? false : null,"label":"title","clearable":false},model:{value:(_vm.vehicleData.condition),callback:function ($$v) {_vm.$set(_vm.vehicleData, "condition", $$v)},expression:"vehicleData.condition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Mileage Unit*","label-for":"mileageUnit"}},[_c('validation-provider',{attrs:{"name":"Mileage Unit","rules":_vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-inception' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dropMileageUnit,"disabled":_vm.vehicleData.editable == 'no',"clearable":false,"label":"title"},model:{value:(_vm.vehicleData.mileageUnit),callback:function ($$v) {_vm.$set(_vm.vehicleData, "mileageUnit", $$v)},expression:"vehicleData.mileageUnit"}}),(_vm.vehicleData.mileageUnit == null && (_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception'))?_c('small',{staticClass:"text-danger"},[_vm._v("The Mileage Unit field is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Exterior Colour*","label-for":"extColour"}},[_c('validation-provider',{attrs:{"name":"Exterior Colour","rules":_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.vehicleData.editable == 'no',"taggable":true,"create-option":function (temp) { return ({ id: 0, value: temp, who: 'extColour' }); },"loading":_vm.dropdownLoading,"value":_vm.vehicleData.extColour,"reduce":function (val) { return val.id; },"options":_vm.dropAll['extColour'],"label":"value","clearable":true},on:{"option:created":_vm.createNewDropdownValue,"option:selected":_vm.selectedDropdownValue},model:{value:(_vm.vehicleData.extColour),callback:function ($$v) {_vm.$set(_vm.vehicleData, "extColour", $$v)},expression:"vehicleData.extColour"}}),(_vm.vehicleData.extColour == null && (_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception'))?_c('small',{staticClass:"text-danger"},[_vm._v("The Exterior Colour field is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Model Year*","label-for":"year"}},[_c('validation-provider',{attrs:{"name":"Model Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"taggable":true,"create-option":function (temp) { return ({ id: 0, value: temp, who: 'modelYear' }); },"loading":_vm.dropdownLoading,"reduce":function (val) { return val.id; },"options":_vm.dropAll['modelYear'],"label":"value","clearable":true,"disabled":_vm.oldCardDisabled || _vm.vehicleData.editable == 'no'},on:{"option:created":_vm.createNewDropdownValue,"option:selected":_vm.selectedDropdownValue},model:{value:(_vm.vehicleData.modelYear),callback:function ($$v) {_vm.$set(_vm.vehicleData, "modelYear", $$v)},expression:"vehicleData.modelYear"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Mileage*","label-for":"mileage"}},[_c('validation-provider',{attrs:{"name":"Mileage","rules":_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mileage","disabled":_vm.vehicleData.editable == 'no',"type":"number"},model:{value:(_vm.vehicleData.mileageValue),callback:function ($$v) {_vm.$set(_vm.vehicleData, "mileageValue", $$v)},expression:"vehicleData.mileageValue"}}),(_vm.vehicleData.mileageValue == null && (_vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-inception'))?_c('small',{staticClass:"text-danger"},[_vm._v("The Mileage field is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Interior Colour*","label-for":"intColour"}},[_c('validation-provider',{attrs:{"name":"Interior Colour","rules":_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"taggable":true,"create-option":function (temp) { return ({ id: 0, value: temp, who: 'intColour' }); },"loading":_vm.dropdownLoading,"value":_vm.vehicleData.intColour,"reduce":function (val) { return val.id; },"options":_vm.dropAll['intColour'],"label":"value","clearable":true,"disabled":_vm.vehicleData.editable == 'no'},on:{"option:created":_vm.createNewDropdownValue,"option:selected":_vm.selectedDropdownValue},model:{value:(_vm.vehicleData.intColour),callback:function ($$v) {_vm.$set(_vm.vehicleData, "intColour", $$v)},expression:"vehicleData.intColour"}}),(_vm.vehicleData.intColour == null && (_vm.wherefrom == 'inventory-edit-sale' || _vm.wherefrom == 'inventory-edit-purchase' || _vm.wherefrom == 'inventory-edit-inception'))?_c('small',{staticClass:"text-danger"},[_vm._v("The Interior Colour field is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Make*","label-for":"make"}},[_c('validation-provider',{attrs:{"name":"Make","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"taggable":true,"create-option":function (temp) { return ({ id: 0, value: temp, who: 'make' }); },"loading":_vm.dropdownLoading,"value":_vm.vehicleData.make,"state":errors.length > 0 ? false : null,"options":_vm.dropAll['make'],"reduce":function (val) { return val.id; },"label":"value","clearable":true,"disabled":_vm.oldCardDisabled || _vm.vehicleData.editable == 'no'},on:{"option:created":_vm.createNewDropdownValue,"option:selected":_vm.selectedDropdownValue,"input":function($event){return _vm.modelClear()}},model:{value:(_vm.vehicleData.make),callback:function ($$v) {_vm.$set(_vm.vehicleData, "make", $$v)},expression:"vehicleData.make"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Model*","label-for":"model"}},[_c('validation-provider',{attrs:{"name":"Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"taggable":true,"create-option":function (temp) { return ({ id: 0, value: temp, who: 'model' }); },"loading":_vm.dropdownLoading,"value":_vm.vehicleData.model,"state":errors.length > 0 ? false : null,"options":_vm.modelOptionsVal,"reduce":function (val) { return val.id; },"label":"value","clearable":true,"disabled":!_vm.vehicleData.make > 0 || _vm.oldCardDisabled || _vm.vehicleData.editable == 'no'},on:{"option:created":_vm.createNewDropdownValue,"option:selected":_vm.selectedDropdownValue,"input":function($event){return _vm.trimClear()}},model:{value:(_vm.vehicleData.model),callback:function ($$v) {_vm.$set(_vm.vehicleData, "model", $$v)},expression:"vehicleData.model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }